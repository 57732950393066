import React, { useState, useEffect, useRef, useCallback, Fragment } from 'react'
import { useParams, Link } from 'react-router-dom';

import { useTimer } from "react-timer-hook";
import { Signer, Auth} from 'aws-amplify';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import moment from 'moment';
import { API } from 'aws-amplify';
import { Helmet, HelmetProvider } from 'react-helmet-async'

import { AiFillSetting, AiOutlineClose } from 'react-icons/ai';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Slider from '@mui/material/Slider';
import {VolumeDown, VolumeUp, MusicNote, } from '@mui/icons-material';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import { createTheme, ThemeProvider} from '@mui/material/styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
const theme = createTheme({
  palette: {
    primary: {
      main: '#767676',
    },
    secondary: {
      main: '#fff',
    },
  },
});

const useWindowDimensions = () => {

  const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    const onResize = () => {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, []);
  return windowDimensions;
}

export default function Client(props) {

  const { hostId } = useParams()
  const startTime = useRef(moment());

  const isRinging = useRef(false);
  const [isSetting, setIsSetting] = useState(false);
  const [volume, setVolume] = useState(70);
  const [isEnableAlarm, setIsEnableAlarm] = useState(true)
  const [isEnableOperationSound, setIsEnableOperationSound] = useState(true)
  const [isEnableBGM, setIsEnableBGM] = useState(false)

  const [isConfirmSound, setIsConfirmSound] = useState(false)

  const [socketUrl, setSocketUrl] = useState(null);

  const [ringtone] = useState(new Audio('/sounds/ringtone.mp3'))
  const [music] = useState(new Audio('/sounds/inspire.mp3'))

  const { width, height } = useWindowDimensions();

  const [isDisconnectByHost, setIsDisconnectByHost] = useState(false)
  const isFirstConnect = useRef(true)

  const {
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    // start,
    // pause,
    // resume,
    restart,
  } = useTimer({
    expiryTimestamp: new Date(),
    autoStart: false,
    onExpire: () => {
      console.log("onExpire called")
      ringtone.play()
      isRinging.current = true
      setTimeout(() => {
        ringtone.pause()
        ringtone.currentTime = 0
        isRinging.current = false
      }, ringtone.duration * 8 * 1000)

      startTime.current = moment()
    }
  });



  const websocket = useCallback(async () => {
    const credentials = await Auth.currentCredentials();

    const accessInfo = {
      access_key: credentials.accessKeyId,
      secret_key: credentials.secretAccessKey,
      session_token: credentials.sessionToken,
    }

    let signedUrl = Signer.signUrl('wss://uw6z1ar8o3.execute-api.ap-northeast-1.amazonaws.com/v1?hostId=' + hostId + '&isHost=false', accessInfo)
    console.log(signedUrl)
    setSocketUrl(signedUrl)
  }, [hostId])


  const {
    // sendMessage,
    // sendJsonMessage,
    // lastMessage,
    // lastJsonMessage,
    readyState,
    getWebSocket,
  } = useWebSocket(socketUrl, {
    onOpen: (e) => {
      console.log(new Date());
      console.log('opened');
      console.log('event: ', e);

      startTime.current = moment()

      let apiName = 'MyAPIGatewayAPI'; // replace this with your api name.
      let path = '/time/' + hostId; //replace this with the path you have configured on your API

      API.get(apiName, path).then(response => {
        console.log(response);
        let data = response.data
        if(data.status === 'resume'){
          restart(new Date(data.date).setSeconds(new Date(data.date).getSeconds() + data.days * 24 * 60 * 60 + data.hours * 60 * 60 + data.minutes * 60 + data.seconds), true)
        }else{
          restart(new Date().setSeconds(new Date().getSeconds() + data.days * 24 * 60 * 60 + data.hours * 60 * 60 + data.minutes * 60 + data.seconds), false)
        }

      }).catch(error => {
        console.log(error);
      })
    },
    onClose: (e) => {

      console.log('closed')
      console.log('event: ', e);
      console.log('startTime:', startTime.current.format('YYYY-MM-DD HH:mm'));
      console.log('diff: ', moment().diff(startTime.current, 'hours'))

      if(e.code === 1000){
        setIsDisconnectByHost(true)
      }

      if(moment().diff(startTime.current, 'hours') < 3 && e.code !== 1000){
        websocket()
      }

    },
    onMessage: (e) => {
      console.log('on message')
      console.log('event: ', e);
      let data = JSON.parse(e.data)

      if(data.status === 'resume'){
        restart(new Date(data.date).setSeconds(new Date(data.date).getSeconds() + data.days * 24 * 60 * 60 + data.hours * 60 * 60 + data.minutes * 60 + data.seconds), true)
      }else{
        restart(new Date().setSeconds(new Date().getSeconds() + data.days * 24 * 60 * 60 + data.hours * 60 * 60 + data.minutes * 60 + data.seconds), false)
      }

      startTime.current = moment()
    }
  });

  const connectionStatus = {
    [ReadyState.CONNECTING]: 1,
    [ReadyState.OPEN]: 1,
    [ReadyState.CLOSING]: 0,
    [ReadyState.CLOSED]: 0,
    [ReadyState.UNINSTANTIATED]: 0,
  }[readyState];



  useEffect(() => {
    if(
      isConfirmSound &&
      connectionStatus === 0 &&
      isFirstConnect.current &&
      !isDisconnectByHost
    ){
      isFirstConnect.current = false
      websocket()
    }

    window.addEventListener("beforeunload", () => {

      if(connectionStatus === 1){
        getWebSocket().close(4000, JSON.stringify({hostId : hostId, isHost: false}));
      }

    });

    return () => {

      if(connectionStatus === 1){
        getWebSocket().close(4000, JSON.stringify({hostId : hostId, isHost: false}));
      }

    };
  }, [isDisconnectByHost, getWebSocket, isConfirmSound, connectionStatus, hostId, websocket]);



  useEffect(() => {

    if(ringtone.readyState === 0 && music.readyState === 0){
      ringtone.load()
      ringtone.loop = true
      music.load()
      music.loop = true
    }

  }, [ringtone, music])


  useEffect(() => {
    if(isRunning){
      music.play()
    }else{
      music.pause()
      music.currentTime = 0
    }
  }, [isRunning, music])


  useEffect(() => {
    if(isEnableAlarm){
      ringtone.volume = volume/100
    }else{
      ringtone.volume = 0
    }

    if(isEnableBGM){
      music.volume = volume/100
    }else{
      music.volume = 0
    }

  }, [volume, music, ringtone, isEnableAlarm, isEnableBGM])


  const confirmSound = () => {
    // audioをユーザ操作起因で一度再生させておくための処理
    if(isEnableAlarm){
      ringtone.volume = 0
      ringtone.play()
      ringtone.pause()
      ringtone.currentTime = 0
      ringtone.volume = volume/100
    }else{
      ringtone.volume = 0
      ringtone.play()
      ringtone.pause()
      ringtone.currentTime = 0
    }

    if(isEnableBGM){
      music.volume = 0
      music.play()
      music.pause()
      music.currentTime = 0
      music.volume = volume/100
    }else{
      music.volume = 0
      music.play()
      music.pause()
      music.currentTime = 0
    }

    setIsConfirmSound(true)

  }

  const stopAlarm = () => {
    if(isRinging.current){
      ringtone.pause();
      ringtone.currentTime = 0;
      isRinging.current = false
    }
  }



  return (
    <div style={{textAlign: 'center'}}>

      <HelmetProvider>
        <Helmet>

        {props.lang === 'ja' &&
          <Fragment>
            <meta
              name="description"
              content="ワークショップ等でご利用いただけるタイマーです。ワークショップ参加者にオンラインでタイマーで共有できます。直感的に残り時間を把握でき、ワーク中にBGMを流すことも可能です。どうぞご利用ください。"
            />
            <title>Workshop Share Timer || 無料のオンラインで共有できるタイマー</title>
          </Fragment>
        }

        {props.lang === 'en' &&
          <Fragment>
            <meta
              name="description"
              content="This is a time timer for a workshop. You can share the timer online with workshop participants. You can also intuitively know how much time is left and play background music during the work."
            />
            <title>Workshop Share Timer || FREE Online Timer To Share!</title>
          </Fragment>
        }

        </Helmet>
      </HelmetProvider>

      <div
        id="confirm-modal"
        style={{display: isConfirmSound ? 'none' : ''}}
      >
        <h1>Workshop Share Timer</h1>

        <div>

          {props.lang === 'ja' &&
            <p style={{fontSize: width > height ? 0.015 * height : 0.015 * width }}>
              このアプリでは音が再生されます。<br/>
              タイマー右下の設定から音量や、音のオンオフができます。
            </p>
          }

          {props.lang === 'en' &&
            <p style={{fontSize: width > height ? 0.015 * height : 0.015 * width }}>
              This application will play audio.<br/>
              You can change the volume and on/off from the settings.
            </p>
          }

        </div>
        <button
          id="confirm"
          onClick={() => confirmSound()}
          style={{fontSize: width > height ? 0.035 * height : 0.035 * width }}
        >
          Start
        </button>

      </div>


      <div
        id="reconnect-modal"
        style={{display: connectionStatus === 0 && isConfirmSound ? '' : 'none'}}
      >
        <h1>Workshop Share Timer</h1>

        <div>

          {
            !isDisconnectByHost && props.lang === 'ja' &&
            <p style={{fontSize: width > height ? 0.015 * height : 0.015 * width }}>
              ネットワーク接続が切断されました。 <br/>
              再接続してください。<br/><br/>

              再接続できない場合はURLを共有してくれたホストにお問い合わせください。
            </p>
          }

          {
            isDisconnectByHost && props.lang === 'ja' &&
            <p style={{fontSize: width > height ? 0.015 * height : 0.015 * width }}>
              ホストによりネットワーク接続が切断されました。<br/>
              続けて利用する場合は、ホストの接続が復帰したあとに再接続を行ってください。
            </p>
          }

          {
            !isDisconnectByHost && props.lang === 'en' &&
            <p style={{fontSize: width > height ? 0.015 * height : 0.015 * width }}>
              The connection has been lost. <br/>
              Please reconnect.<br/><br/>

              If you are unable to reconnect, please contact your host.
            </p>
          }

          {
            isDisconnectByHost && props.lang === 'en' &&
            <p style={{fontSize: width > height ? 0.015 * height : 0.015 * width }}>
              Connection has been terminated by the host.<br/>
              If the host resumes, please try to reconnect.
            </p>
          }


        </div>
        <button
          id="reconnect"
          onClick={() => {
            websocket()
            setIsDisconnectByHost(false)
            startTime.current = moment()
          }}
          style={{fontSize: width > height ? 0.035 * height : 0.035 * width}}
        >
          Connect
        </button>

      </div>

      <h1>Workshop Share Timer</h1>
      <section
        id="timer"
        style={{
          width: width > height ? 0.8 * height : 0.85 * width,
          height: width > height ? 0.8 * height : 0.85 * width,
        }}
      >
        <div
          id="clock"
          style={{
            backgroundImage: 'conic-gradient(#ffffff 0% ' + ((360 - (days * 24 * 60 + hours * 60 + minutes + seconds/60) * 6)/360) * 100 + '%, #ff3c3c ' + ((360 - (days * 24 * 60 + hours * 60 + minutes + seconds/60) * 6)/360) * 100 + '% 100%)',
          }}
        >

          {(() => {
            const faceBoxes = []
            for (let i = 1; i <= 60; i++) {
              if(i === 15 || i === 30 || i === 45 || i === 60){
                faceBoxes.push(
                  <div key={i} className="faceBox" style={{transform: 'rotate(' + i * 6 + 'deg)'}}>
                    <div className="largeTick"></div>
                    <div
                      className="tickNum"
                      style={{
                        transform: 'rotate(-' + i * 6 + 'deg)',
                        fontSize: width > height ? 0.05 * height : 0.0525 * width
                      }}
                    >
                      {String(60-i).padStart(2,'0')}
                    </div>
                  </div>
                )
              }else if(i === 5 || i === 10 || i === 20 || i === 25 || i === 35 || i === 40 || i === 50 || i === 55){
                faceBoxes.push(
                  <div key={i} className="faceBox" style={{transform: 'rotate(' + i * 6 + 'deg)'}}>
                    <div className="mediumTick"></div>
                    <div
                      className="tickNum"
                      style={{
                        transform: 'rotate(-' + i * 6 + 'deg)',
                        fontSize: width > height ? 0.05 * height : 0.0525 * width
                      }}
                    >
                      {String(60-i).padStart(2,'0')}
                    </div>
                  </div>
                )
              }else{
                faceBoxes.push(
                  <div key={i} className="faceBox" style={{transform: 'rotate(' + i * 6 + 'deg)'}}>
                    <div className="smallTick"></div>
                  </div>
                )
              }
            }
            return <Fragment>{faceBoxes}</Fragment>;
          })()}

          <div
            id="time-is-up1"
            style={{
              fontSize: width > height ? 0.05 * height : 0.0525 * width,
              animation: isRinging.current ? 'flash ' +  ringtone.duration + 's linear infinite' : '',
              display: isRinging.current ? '' : 'none'
            }}
          >
            TIME IS UP
          </div>

          <div
            id="time-is-up2"
            style={{
              fontSize: width > height ? 0.05 * height : 0.0525 * width,
              animation: isRinging.current ? 'flash ' +  ringtone.duration + 's linear infinite': '',
              display: isRinging.current ? '' : 'none'
            }}
          >
            TIME IS UP
          </div>

        </div>

        <div id="reset">
          <button
            onClick={() => stopAlarm()}
            style={{fontSize: width > height ? 0.02 * height : 0.02 * width, display: isRinging.current ? '' : 'none'}}
            className="not-running"
          >
            stop<br/>sound
          </button>
        </div>

        <div
          id="center"
        >
          <div id="circle">

            <div id="time" style={{fontSize: width > height ? 0.0448 * height : 0.0475 * width}}>
              <span id="time-min">{String(days * 24 * 60 + hours * 60 + minutes).padStart(2,'0')}</span> <span id ="colon">:</span> <span id="time-sec">{String(seconds).padStart(2,'0')}</span>
            </div>

          </div>

        </div>



        <div id="settings">
          <button
            id="settings-open"
            onClick={() => setIsSetting(!isSetting)}
            style={{fontSize: width > height ? 0.02 * height : 0.02 * width}}
          >
            <AiFillSetting style={{marginTop: '10%'}}/>
          </button>



          <div
            id="settings-modal"
            style={{display: isSetting ? '' : 'none'}}
            onMouseLeave={() => setIsSetting(false)}
          >


            <button
              id="close"
              onClick={() => setIsSetting(false)}
            >
              <AiOutlineClose style={{width: width > height ? 0.015 * height : 0.015 * width, height: width > height ? 0.015 * height : 0.015 * width, marginTop: '10%'}}/>
            </button>
            <p style={{fontSize: width > height ? 0.025 * height : 0.025 * width }}>Settings</p>



            <ThemeProvider theme={theme}>

              <Box sx={{ width: '70%', marginLeft: 'auto', marginRight: 'auto', marginTop: '8%'}}>
                <Stack spacing={2} direction="row" alignItems="center">
                  <VolumeDown onClick={() => setVolume(volume -10 > 0 ? volume - 10 : 0)} style={{width: width > height ? 0.03 * height : 0.03 * width, height: width > height ? 0.03 * height : 0.03 * width}}/>
                  <Slider aria-label="Volume" value={volume} onChange={(e) => setVolume(e.target.value)} color='secondary'/>
                  <VolumeUp onClick={() => setVolume(volume + 10 < 100 ? volume + 10 : 100)} style={{width: width > height ? 0.03 * height : 0.03 * width, height: width > height ? 0.03 * height : 0.03 * width}}/>
                </Stack>
              </Box>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                }}
              >
                <FormControlLabel
                  sx={{margin: '8% 0 2% 0'}}
                  labelPlacement="start"
                  control={
                    <Switch
                      checked={isEnableAlarm}
                      onChange={() => setIsEnableAlarm(!isEnableAlarm)}
                      color="secondary"
                    />
                  }
                  label={
                    <div style={{fontSize: width > height ? 0.02 * height : 0.02 * width }}>
                      <NotificationsActiveIcon
                        sx={{
                          verticalAlign: 'middle',
                          width: width > height ? 0.025 * height : 0.025 * width,
                          height: width > height ? 0.025 * height : 0.025 * width
                        }}
                      />
                      {props.lang === 'ja' &&
                        <Fragment>アラーム音</Fragment>
                      }
                      {props.lang === 'en' &&
                        <Fragment>Alarm</Fragment>
                      }
                    </div>
                  }
                />
                <FormControlLabel
                  sx={{margin: '2% 0'}}
                  labelPlacement="start"
                  control={
                    <Switch
                      checked={isEnableOperationSound}
                      onChange={() => setIsEnableOperationSound(!isEnableOperationSound)}
                      color="secondary"
                    />
                  }
                  label={
                    <div style={{fontSize: width > height ? 0.02 * height : 0.02 * width }}>
                      <VolumeUp
                        sx={{
                          verticalAlign: 'middle',
                          width: width > height ? 0.025 * height : 0.025 * width,
                          height: width > height ? 0.025 * height : 0.025 * width
                        }}
                      />
                      {props.lang === 'ja' &&
                        <Fragment>操作音</Fragment>
                      }
                      {props.lang === 'en' &&
                        <Fragment>Operation Sound</Fragment>
                      }
                    </div>
                  }
                />
                <FormControlLabel
                  sx={{margin: '2% 0'}}
                  labelPlacement="start"
                  control={
                    <Switch
                      checked={isEnableBGM}
                      onChange={() => setIsEnableBGM(!isEnableBGM)}
                      color="secondary"
                    />
                  }
                  label={
                    <div style={{fontSize: width > height ? 0.02 * height : 0.02 * width }}>
                      <MusicNote
                        sx={{
                          verticalAlign: 'middle',
                          width: width > height ? 0.025 * height : 0.025 * width,
                          height: width > height ? 0.025 * height : 0.025 * width
                        }}
                      />
                      BGM
                    </div>
                  }
                />
              </div>

            </ThemeProvider>

          </div>

        </div>

      </section>

      {props.lang === 'ja' &&
        <p className='description' style={{fontSize: width > height ? 0.013 * height : 0.013 * width}}>
          English Version is <Link to={'/en/' +hostId}>Here</Link>
        </p>
      }

      {props.lang === 'en' &&
        <p className='description' style={{fontSize: width > height ? 0.013 * height : 0.013 * width}}>
          日本語版は<Link to={'/' + hostId}>こちら</Link>
        </p>
      }

    </div>
  );
}
