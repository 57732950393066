import React, { useState, useEffect, useRef, useCallback, Fragment } from 'react'
import { Link } from "react-router-dom";

import { useTimer } from "react-timer-hook";
import useMouse from '@react-hook/mouse-position'
import { MonoSynth } from "tone";
import { Signer, Auth} from 'aws-amplify';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import copy from 'copy-to-clipboard';
import { Helmet, HelmetProvider } from 'react-helmet-async'

import { AiFillSetting, AiOutlineClose, AiOutlineLink } from 'react-icons/ai';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Slider from '@mui/material/Slider';
import {VolumeDown, VolumeUp, MusicNote, } from '@mui/icons-material';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import { createTheme, ThemeProvider} from '@mui/material/styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
const theme = createTheme({
  palette: {
    primary: {
      main: '#767676',
    },
    secondary: {
      main: '#fff',
    },
  },
});

const useWindowDimensions = () => {

  const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    const onResize = () => {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, []);
  return windowDimensions;
}

export default function Host(props) {

  const [hostId] = useState(uuidv4());

  const startTime = useRef(moment());

  const isRinging = useRef(false);
  const [isOverCenter, setIsOverCenter] = useState(false);
  const [isSetting, setIsSetting] = useState(false);
  const [isUrl, setIsUrl] = useState(false);
  const [volume, setVolume] = useState(70);
  const [isEnableAlarm, setIsEnableAlarm] = useState(true)
  const [isEnableOperationSound, setIsEnableOperationSound] = useState(true)
  const [isEnableBGM, setIsEnableBGM] = useState(false)

  const [timer, setTimer] = useState(
    {
      hostId: hostId,
      status: 'pause',
      date: new Date(),
      seconds: 0,
      minutes: 0,
      hours: 0,
      days: 0
    }
  )
  const [isChanging, setIsChanging] = useState(false)

  const [socketUrl, setSocketUrl] = useState(null);

  const intervalRef = useRef(null);

  const isFirstConnect = useRef(true)

  const websocket = useCallback(async () => {
    const credentials = await Auth.currentCredentials();

    const accessInfo = {
      access_key: credentials.accessKeyId,
      secret_key: credentials.secretAccessKey,
      session_token: credentials.sessionToken,
    }

    let signedUrl = Signer.signUrl('wss://uw6z1ar8o3.execute-api.ap-northeast-1.amazonaws.com/v1?hostId=' + hostId + '&isHost=true&data=' + JSON.stringify(timer), accessInfo)
    console.log(signedUrl)
    setSocketUrl(signedUrl)
  }, [hostId, timer])


  const {
    // sendMessage,
    sendJsonMessage,
    // lastMessage,
    // lastJsonMessage,
    readyState,
    getWebSocket,
  } = useWebSocket(socketUrl, {
    onOpen: (e) => {
      console.log(new Date());
      console.log('opened');
      console.log('event: ', e);
    },
    onClose: (e) => {

      console.log('closed')
      console.log('event: ', e);
      console.log('startTime:', startTime.current.format('YYYY-MM-DD HH:mm'));
      console.log('diff: ', moment().diff(startTime.current, 'hours'))

      if(moment().diff(startTime.current, 'hours') < 3 || isRunning){
        websocket()
      }

    },
  });

  const connectionStatus = {
    [ReadyState.CONNECTING]: 1,
    [ReadyState.OPEN]: 1,
    [ReadyState.CLOSING]: 0,
    [ReadyState.CLOSED]: 0,
    [ReadyState.UNINSTANTIATED]: 0,
  }[readyState];


  useEffect(() => {

    if(
      connectionStatus === 0 &&
      isUrl &&
      isFirstConnect.current
    ){
      isFirstConnect.current = false
      websocket()
    }

    window.addEventListener("beforeunload", () => {

      if(connectionStatus === 1){
        getWebSocket().close(4000, JSON.stringify({hostId : hostId, isHost: true}));
      }

    });

    return () => {

      if(connectionStatus === 1 && hostId.curret){
        getWebSocket().close(4000, JSON.stringify({hostId : hostId, isHost: true}));
      }

    };
  }, [hostId, isUrl, getWebSocket, connectionStatus, websocket]);


  useEffect(() => {
    return () => onMouseUp(); // when App is unmounted we should stop counter
  }, []);

  const [ringtone] = useState(new Audio('/sounds/ringtone.mp3'))
  const [music] = useState(new Audio('/sounds/inspire.mp3'))

  let monoSynth = React.useRef()

  const { width, height } = useWindowDimensions();

  const ref = useRef(null)
  const mouse = useMouse(ref, {
    enterDelay: 10,
    leaveDelay: 10,
    fps: 10
  })

  const {
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    // start,
    // pause,
    // resume,
    restart,
  } = useTimer({
    expiryTimestamp: new Date(),
    autoStart: false,
    onExpire: () => {
      console.log("onExpire called")
      ringtone.play()
      isRinging.current = true
      setTimeout(() => {
        ringtone.pause()
        ringtone.currentTime = 0
        isRinging.current = false
      }, ringtone.duration * 8 * 1000)

      changeTimer({
        hostId: hostId,
        status: 'pause',
        date: new Date().getTime(),
        seconds: 0,
        minutes: 0,
        hours: 0,
        days: 0
      })

      startTime.current = moment()
    }
  });

  useEffect(() => {

    if(ringtone.readyState === 0 && music.readyState === 0){
      ringtone.load()
      ringtone.loop = true
      music.load()
      music.loop = true
    }

    monoSynth.current = new MonoSynth({
    	"volume": 0,
    	"detune": 0,
    	"portamento": 0,
    	"envelope": {
    		"attack": 0.05,
    		"attackCurve": "linear",
    		"decay": 0.3,
    		"decayCurve": "exponential",
    		"release": 0.8,
    		"releaseCurve": "exponential",
    		"sustain": 0.4
    	},
    	"filter": {
    		"Q": 1,
    		"detune": 0,
    		"frequency": 0,
    		"gain": 0,
    		"rolloff": -12,
    		"type": "lowpass"
    	},
    	"filterEnvelope": {
    		"attack": 0.001,
    		"attackCurve": "linear",
    		"decay": 0.7,
    		"decayCurve": "exponential",
    		"release": 0.8,
    		"releaseCurve": "exponential",
    		"sustain": 0.1,
    		"baseFrequency": 300,
    		"exponent": 2,
    		"octaves": 4
    	},
    	"oscillator": {
    		"detune": 0,
    		"frequency": 440,
    		"partialCount": 8,
    		"partials": [
    			1.2732395447351628,
    			0,
    			0.4244131815783876,
    			0,
    			0.25464790894703254,
    			0,
    			0.18189136353359467,
    			0
    		],
    		"phase": 0,
    		"type": "square8"
    	}
    }).toDestination();
  }, [ringtone, music])


  useEffect(() => {
    if(isRunning){
      music.play()
    }else{
      music.pause()
      music.currentTime = 0
    }
  }, [isRunning, music])


  useEffect(() => {
    if(isEnableAlarm){
      ringtone.volume = volume/100
    }else{
      ringtone.volume = 0
    }

    if(isEnableBGM){
      music.volume = volume/100
    }else{
      music.volume = 0
    }

    if(isEnableOperationSound){
      monoSynth.current.volume.value = -40 + 50 * volume/100
    }else{
      monoSynth.current.volume.value = -50
    }

  }, [volume, music, ringtone, isEnableAlarm, isEnableBGM, isEnableOperationSound])


  const startOrPause = () => {
    monoSynth.current.triggerAttackRelease('E7', 0.005)

    // audioをユーザ操作起因で一度再生させておくための処理
    if(isEnableAlarm){
      ringtone.volume = 0
      ringtone.play()
      ringtone.pause()
      ringtone.currentTime = 0
      ringtone.volume = volume/100
    }else{
      ringtone.volume = 0
      ringtone.play()
      ringtone.pause()
      ringtone.currentTime = 0
    }

    if(isEnableBGM){
      music.volume = 0
      music.play()
      music.pause()
      music.currentTime = 0
      music.volume = volume/100
    }else{
      music.volume = 0
      music.play()
      music.pause()
      music.currentTime = 0
    }

    if(isRunning){
      // pause()
      changeTimer(
        {
          hostId: hostId,
          status: 'pause',
          date: new Date().getTime(),
          seconds: seconds,
          minutes: minutes,
          hours: hours,
          days: days
        }
      )
    }else{
      if(seconds > 0 || minutes > 0 || hours > 0 || days > 0){
        // resume()
        changeTimer(
          {
            hostId: hostId,
            status: 'resume',
            date: new Date().getTime(),
            seconds: seconds,
            minutes: minutes,
            hours: hours,
            days: days
          }
        )
      }
      if(isRinging.current){
        ringtone.pause()
        ringtone.currentTime = 0;
        isRinging.current = false
      }
    }
  }

  const reset = () => {
    monoSynth.current.triggerAttackRelease('E7', 0.005)

    if(isRinging.current){
      ringtone.pause();
      ringtone.currentTime = 0;
      isRinging.current = false
    }

    changeTimer(
      {
        hostId: hostId,
        status: 'pause',
        date: new Date().getTime(),
        seconds: 0,
        minutes: 0,
        hours: 0,
        days: 0
      }
    )
  }

  const add = (additionalSeconds) => {
    monoSynth.current.triggerAttackRelease('E7', 0.005)
    changeTimer(
      {
        hostId: hostId,
        status: isRunning ? 'resume' : 'pause',
        date: new Date().getTime(),
        seconds: seconds + additionalSeconds,
        minutes: minutes,
        hours: hours,
        days: days
      }
    )

  }

  const onMouseDown = (additionalSeconds) => {
    add(additionalSeconds)
    if (intervalRef.current) return;
    let s = additionalSeconds
    let n = 0
    intervalRef.current = setInterval(() => {
      add(additionalSeconds  + s)
      s = s + additionalSeconds
      n++
      if(n > 3){
        clearInterval(intervalRef.current);
        intervalRef.current = setInterval(() => {
          add(additionalSeconds  + s)
          s = s + additionalSeconds
        }, 100);
      }
    }, 400);
  };

  const onMouseUp = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  };

  const getTime = () => {
    if(mouse.x){
      let degrees = Math.atan2(mouse.elementWidth/2 - mouse.x, mouse.elementHeight/2 - mouse.y)*180/Math.PI
      if(degrees < 0){
        degrees = 360 + degrees
      }

      let time =  Math.floor(degrees / 6)

      if(time === 0){
        time = 60
      }
      return time
    }

    return null

  }

  const setTime = () => {
    monoSynth.current.triggerAttackRelease('E7', 0.005)

    changeTimer(
      {
        hostId: hostId,
        status: isRunning ? 'resume' : 'pause',
        date: new Date().getTime(),
        seconds: getTime() * 60,
        minutes: 0,
        hours: 0,
        days: 0
      }
    )
  }

  const changeTimer = (value) => {
    setTimer(value)
    setIsChanging(true)
  }


  useEffect(() => {

    if(timer.status === 'resume'){
      restart(
        new Date(timer.date).setSeconds(new Date(timer.date).getSeconds() +
        timer.days * 24 * 60 * 60 +
        timer.hours * 60 * 60 +
        timer.minutes * 60 +
        timer.seconds
      ), true)
    }else{
      restart(
        new Date().setSeconds(new Date().getSeconds() +
        timer.days * 24 * 60 * 60 +
        timer.hours * 60 * 60 +
        timer.minutes * 60 +
        timer.seconds
      ), false)
    }

    const timeoutId = setTimeout(() => {
      if(isChanging){

        console.log('send: ', timer)

        const body = {
          action: 'sendmessage',
          data: timer,
        };

        sendJsonMessage(body)

        setIsChanging(false)

        startTime.current = moment()
      }
    }, 1000)
    return () => clearTimeout(timeoutId);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timer, isChanging, sendJsonMessage]);






  return (
    <div style={{textAlign: 'center'}}>

      <HelmetProvider>
        <Helmet>
        {props.lang === 'ja' &&
          <Fragment>
            <meta
              name="description"
              content="ワークショップ等でご利用いただけるタイマーです。ワークショップ参加者にオンラインでタイマーを共有できます。直感的に残り時間を把握でき、ワーク中にBGMを流すことも可能です。どうぞご利用ください。"
            />
            <title>Workshop Share Timer || 無料オンライン共有タイマー</title>
          </Fragment>
        }

        {props.lang === 'en' &&
          <Fragment>
            <meta
              name="description"
              content="This is a time timer for a workshop. You can share the timer online with workshop participants. You can also intuitively know how much time is left and play background music during the work."
            />
            <title>Workshop Share Timer || FREE Online Workshop Timer To Share!</title>
          </Fragment>
        }

        </Helmet>
      </HelmetProvider>

      <div
        id="reconnect-modal"
        style={{display: connectionStatus === 0 && !isFirstConnect.current ? '' : 'none'}}
      >
        <h1>Workshop Share Timer</h1>

        <div>

          {props.lang === 'ja' &&
            <p style={{fontSize: width > height ? 0.015 * height : 0.015 * width }}>
              ネットワーク接続が切断されました。 <br/>
              再接続してください。<br/>
            </p>
          }

          {props.lang === 'en' &&
            <p style={{fontSize: width > height ? 0.015 * height : 0.015 * width }}>
              The connection has been lost. <br/>
              Please reconnect.<br/>
            </p>
          }

        </div>
        <button
          id="reconnect"
          onClick={() => {
            websocket()
            startTime.current = moment()
          }}
          style={{fontSize: width > height ? 0.035 * height : 0.035 * width }}
        >
          Connect
        </button>

      </div>

      <h1>Workshop Share Timer</h1>
      <section
        id="timer"
        style={{
          width: width > height ? 0.8 * height : 0.85 * width,
          height: width > height ? 0.8 * height : 0.85 * width,
        }}
      >
        <div
          id="clock"
          ref={ref}
          onClick={() => {
            if(!window.matchMedia("(pointer: coarse)").matches){
              setTime()
            }
          }}
          onTouchEnd={() => {
            if(window.matchMedia("(pointer: coarse)").matches){
              setTime()
            }
          }}
          style={{
            backgroundImage:
              getTime() ?
              'conic-gradient(#ffffff 0% ' + ((360 - getTime() * 6)/360) * 100 + '%, #ffb5b5 ' + ((360 - getTime() * 6)/360) * 100 + '% 100%)' :
              'conic-gradient(#ffffff 0% ' + ((360 - (days * 24 * 60 + hours * 60 + minutes + seconds/60) * 6)/360) * 100 + '%, #ff3c3c ' + ((360 - (days * 24 * 60 + hours * 60 + minutes + seconds/60) * 6)/360) * 100 + '% 100%)',
          }}
        >

          <div
            id="second"
            style={{
              transform: 'rotate(' + (360 - (days * 24 * 60 + hours * 60 + minutes + seconds/60) * 6) + 'deg)',
              backgroundColor: getTime() ? "red" : "#242424",
              display: isRunning ? getTime() ? '' : 'none' : ''
            }}
          >
          </div>

          {(() => {
            const faceBoxes = []
            for (let i = 1; i <= 60; i++) {
              if(i === 15 || i === 30 || i === 45 || i === 60){
                faceBoxes.push(
                  <div key={i} className="faceBox" style={{transform: 'rotate(' + i * 6 + 'deg)'}}>
                    <div className="largeTick"></div>
                    <div
                      className="tickNum"
                      style={{
                        transform: 'rotate(-' + i * 6 + 'deg)',
                        fontSize: width > height ? 0.05 * height : 0.0525 * width
                      }}
                    >
                      {String(60-i).padStart(2,'0')}
                    </div>
                  </div>
                )
              }else if(i === 5 || i === 10 || i === 20 || i === 25 || i === 35 || i === 40 || i === 50 || i === 55){
                faceBoxes.push(
                  <div key={i} className="faceBox" style={{transform: 'rotate(' + i * 6 + 'deg)'}}>
                    <div className="mediumTick"></div>
                    <div
                      className="tickNum"
                      style={{
                        transform: 'rotate(-' + i * 6 + 'deg)',
                        fontSize: width > height ? 0.05 * height : 0.0525 * width
                      }}
                    >
                      {String(60-i).padStart(2,'0')}
                    </div>
                  </div>
                )
              }else{
                faceBoxes.push(
                  <div key={i} className="faceBox" style={{transform: 'rotate(' + i * 6 + 'deg)'}}>
                    <div className="smallTick"></div>
                  </div>
                )
              }
            }
            return <Fragment>{faceBoxes}</Fragment>;
          })()}

          <div
            id="time-is-up1"
            style={{
              fontSize: width > height ? 0.05 * height : 0.0525 * width,
              animation: isRinging.current ? 'flash ' +  ringtone.duration + 's linear infinite' : '',
              display: isRinging.current ? '' : 'none'
            }}
          >
            TIME IS UP
          </div>

          <div
            id="time-is-up2"
            style={{
              fontSize: width > height ? 0.05 * height : 0.0525 * width,
              animation: isRinging.current ? 'flash ' +  ringtone.duration + 's linear infinite': '',
              display: isRinging.current ? '' : 'none'
            }}
          >
            TIME IS UP
          </div>

        </div>

        <div id="start-or-pause">
          <button
            onClick={() => startOrPause()}
            style={{fontSize: width > height ? 0.02 * height : 0.02 * width}}
            className={isRunning ? "pause" : isRinging.current ? "stop-sound" : "start"}
            disabled={ringtone.readyState === 0 && music.readyState === 0}
          >
            {isRunning ? "pause" : isRinging.current ? <Fragment>stop<br/>sound</Fragment> : "start"}
          </button>
        </div>

        <div id="reset">
          <button
            onClick={() => reset()}
            style={{fontSize: width > height ? 0.02 * height : 0.02 * width}}
            className={isRunning ? "running" : "not-running"}
          >
            reset
          </button>
        </div>

        <div
          id="center"
          onMouseEnter={() => setIsOverCenter(true)}
          onMouseLeave={() => setIsOverCenter(false)}
        >
          <div id="circle">

            <div id="time" style={{fontSize: width > height ? 0.0448 * height : 0.0475 * width}}>
              <span id="time-min">{String(days * 24 * 60 + hours * 60 + minutes).padStart(2,'0')}</span> <span id ="colon">:</span> <span id="time-sec">{String(seconds).padStart(2,'0')}</span>
            </div>

            <div id="set-time-buttons">
              <button
                onMouseDown={() => onMouseDown(60)}
                onMouseUp={onMouseUp}
                onMouseLeave={onMouseUp}
                className="plus-min plus"
                style={{
                  fontSize: width > height ? 0.02 * height : 0.02 * width,
                  display: isOverCenter ? '' : isRunning ? 'none' : ''
                }}
                onMouseEnter={() => setIsOverCenter(true)}
              >
                +
              </button>

              <button
                onMouseDown={() => onMouseDown(1)}
                onMouseUp={onMouseUp}
                onMouseLeave={onMouseUp}
                className="plus-sec plus"
                style={{
                  fontSize: width > height ? 0.02 * height : 0.02 * width,
                  display: isOverCenter ? '' : isRunning ? 'none' : ''
                }}
                onMouseEnter={() => setIsOverCenter(true)}
              >
                +
              </button>

              <button
                onMouseDown={() => onMouseDown(-60)}
                onMouseUp={onMouseUp}
                onMouseLeave={onMouseUp}
                className="minus-min minus"
                style={{
                  fontSize: width > height ? 0.02 * height : 0.02 * width,
                  display: isOverCenter ? '' : isRunning ? 'none' : ''
                }}
                onMouseEnter={() => setIsOverCenter(true)}
              >
                -
              </button>

              <button
                onMouseDown={() => onMouseDown(-1)}
                onMouseUp={onMouseUp}
                onMouseLeave={onMouseUp}
                className="minus-sec minus"
                style={{
                  fontSize: width > height ? 0.02 * height : 0.02 * width,
                  display: isOverCenter ? '' : isRunning ? 'none' : ''
                }}
                onMouseEnter={() => setIsOverCenter(true)}
              >
                -
              </button>

            </div>

          </div>

        </div>



        <div id="settings">
          <button
            id="settings-open"
            onClick={() => setIsSetting(!isSetting)}
            style={{fontSize: width > height ? 0.02 * height : 0.02 * width}}
          >
            <AiFillSetting style={{marginTop: '10%'}}/>
          </button>



          <div
            id="settings-modal"
            style={{display: isSetting ? '' : 'none'}}
            onMouseLeave={() => setIsSetting(false)}
          >


            <button
              id="close"
              onClick={() => setIsSetting(false)}
            >
              <AiOutlineClose style={{width: width > height ? 0.015 * height : 0.015 * width, height: width > height ? 0.015 * height : 0.015 * width, marginTop: '10%'}}/>
            </button>
            <p style={{fontSize: width > height ? 0.025 * height : 0.025 * width }}>Settings</p>



            <ThemeProvider theme={theme}>

              <Box sx={{ width: '70%', marginLeft: 'auto', marginRight: 'auto', marginTop: '8%'}}>
                <Stack spacing={2} direction="row" alignItems="center">
                  <VolumeDown onClick={() => setVolume(volume -10 > 0 ? volume - 10 : 0)} style={{width: width > height ? 0.03 * height : 0.03 * width, height: width > height ? 0.03 * height : 0.03 * width}}/>
                  <Slider aria-label="Volume" value={volume} onChange={(e) => setVolume(e.target.value)} color='secondary'/>
                  <VolumeUp onClick={() => setVolume(volume + 10 < 100 ? volume + 10 : 100)} style={{width: width > height ? 0.03 * height : 0.03 * width, height: width > height ? 0.03 * height : 0.03 * width}}/>
                </Stack>
              </Box>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                }}
              >
                <FormControlLabel
                  sx={{margin: '8% 0 2% 0'}}
                  labelPlacement="start"
                  control={
                    <Switch
                      checked={isEnableAlarm}
                      onChange={() => setIsEnableAlarm(!isEnableAlarm)}
                      color="secondary"
                    />
                  }
                  label={
                    <div style={{fontSize: width > height ? 0.02 * height : 0.02 * width }}>
                      <NotificationsActiveIcon
                        sx={{
                          verticalAlign: 'middle',
                          width: width > height ? 0.025 * height : 0.025 * width,
                          height: width > height ? 0.025 * height : 0.025 * width
                        }}
                      />
                      {props.lang === 'ja' &&
                        <Fragment>アラーム音</Fragment>
                      }
                      {props.lang === 'en' &&
                        <Fragment>Alarm</Fragment>
                      }
                    </div>
                  }
                />
                <FormControlLabel
                  sx={{margin: '2% 0'}}
                  labelPlacement="start"
                  control={
                    <Switch
                      checked={isEnableOperationSound}
                      onChange={() => setIsEnableOperationSound(!isEnableOperationSound)}
                      color="secondary"
                    />
                  }
                  label={
                    <div style={{fontSize: width > height ? 0.02 * height : 0.02 * width }}>
                      <VolumeUp
                        sx={{
                          verticalAlign: 'middle',
                          width: width > height ? 0.025 * height : 0.025 * width,
                          height: width > height ? 0.025 * height : 0.025 * width
                        }}
                      />
                      {props.lang === 'ja' &&
                        <Fragment>操作音</Fragment>
                      }
                      {props.lang === 'en' &&
                        <Fragment>Operation Sound</Fragment>
                      }
                    </div>
                  }
                />
                <FormControlLabel
                  sx={{margin: '2% 0'}}
                  labelPlacement="start"
                  control={
                    <Switch
                      checked={isEnableBGM}
                      onChange={() => setIsEnableBGM(!isEnableBGM)}
                      color="secondary"
                    />
                  }
                  label={
                    <div style={{fontSize: width > height ? 0.02 * height : 0.02 * width }}>
                      <MusicNote
                        sx={{
                          verticalAlign: 'middle',
                          width: width > height ? 0.025 * height : 0.025 * width,
                          height: width > height ? 0.025 * height : 0.025 * width
                        }}
                      />
                      BGM
                    </div>
                  }
                />
              </div>

            </ThemeProvider>

          </div>

        </div>



        <div id="url">
          <button
            id="url-open"
            onClick={() => {
              setIsUrl(!isUrl)
              startTime.current = moment()
            }}
            style={{fontSize: width > height ? 0.02 * height : 0.02 * width}}
          >
            <AiOutlineLink style={{marginTop: '10%'}}/>
          </button>



          <div
            id="url-modal"
            style={{display: isUrl ? '' : 'none'}}
            onMouseLeave={() => setIsUrl(false)}
          >


            <button
              id="close"
              onClick={() => setIsUrl(false)}
            >
              <AiOutlineClose style={{width: width > height ? 0.015 * height : 0.015 * width, height: width > height ? 0.015 * height : 0.015 * width, marginTop: '10%'}}/>
            </button>
            <p style={{fontSize: width > height ? 0.025 * height : 0.025 * width }}>Link</p>


            <div>

              {props.lang === 'ja' &&
                <p style={{userSelect: 'text', fontSize: width > height ? 0.015 * height : 0.015 * width}}>
                タイマーをリアルタイムでワークショップ参加者に共有することができます。<br/>
                以下のURLを参加者に共有してください。<br/><br/>
                {process.env.REACT_APP_URL + hostId}
                </p>
              }


              {props.lang === 'en' &&
                <p style={{userSelect: 'text', fontSize: width > height ? 0.015 * height : 0.015 * width}}>
                You can share the timer in real time<br/>
                by sending this URL to the workshop participants.<br/><br/>
                {process.env.REACT_APP_URL +　'en/' + hostId}
                </p>
              }

            </div>

            {props.lang === 'ja' &&
              <button
                id="confirm"
                onClick={() => {
                  copy(process.env.REACT_APP_URL + hostId)
                  setIsUrl(false)
                }}
                style={{fontSize: width > height ? 0.035 * height : 0.035 * width }}
              >
                Copy URL
              </button>
            }


            {props.lang === 'en' &&
              <button
                id="confirm"
                onClick={() => {
                  copy(process.env.REACT_APP_URL + 'en/' + hostId)
                  setIsUrl(false)
                }}
                style={{fontSize: width > height ? 0.035 * height : 0.035 * width }}
              >
                Copy URL
              </button>
            }


          </div>

        </div>

      </section>

      {props.lang === 'ja' &&
        <p className='description' style={{fontSize: width > height ? 0.013 * height : 0.013 * width, margin: '1% 10%'}}>
          ワークショップ等でご利用いただけるタイマーです。ワークショップ参加者にオンラインでタイマーを共有できます。<br/>
          直感的に残り時間を把握でき、ワーク中にBGMを流すことも可能です。どうぞご利用ください。<br/><br/>
          English Version is <Link to="/en">Here</Link>
        </p>
      }

      {props.lang === 'en' &&
        <p className='description' style={{fontSize: width > height ? 0.013 * height : 0.013 * width, margin: '1% 10%'}}>
          This is a time timer for a workshop. You can share the timer online with workshop participants.<br/>
          You can also intuitively know how much time is left and play background music during the work.<br/><br/>
          日本語版は<Link to="/">こちら</Link>
        </p>
      }


    </div>
  );
}
