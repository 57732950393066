import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom'

import Host from './Host';
import Client from './Client';


export default function App(props) {

  return (
    <BrowserRouter>

      <Routes>

        <Route path='/en/:hostId' element={<Client lang='en'  />} />
        <Route path='/:hostId' element={<Client lang='ja' />} />

        <Route path='/en' element={<Host lang='en' />} />
        <Route path='/' element={<Host lang='ja' />} />

        <Route
          path="*"
          element={<Navigate to="/" />}
        />

      </Routes>

    </BrowserRouter>
  );
}
